import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import Accordion from '~2-components/Accordion/Accordion';
import AccordionItem from '~2-components/Accordion/AccordionItem';
import AccordionKontent from '~3-data-components/AccordionKontent';
import { accordionItemsExample } from "~data/accordionItemsExample";

const accordionEg = `import Accordion from '~2-components/Accordion/Accordion';

<Accordion>
    // Children accordion items go here
</Accordion>
`;

const SgAccordion = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allAccordions = useStaticQuery(
        graphql`
            query SGAllAccordions {
                allKontentItemAccordion {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            items {
                                value {
                                    ... on kontent_item_accordion_item {
                                        system {
                                            id
                                            type
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `);

    const allAccordionContainers = allAccordions.allKontentItemAccordion.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Accordion</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={accordionEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <Accordion>
                        {accordionItemsExample.map((item, i) => {
                            return <AccordionItem key={i} {...item} />;
                        })}
                    </Accordion>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>All LIVE Kontent accordions</h3>
                </div>
            </section>
            {allAccordionContainers.map((item, i) => {
                return (
                    <section className="project-example" key={item.system.id}>
                        <div className="container">
                            <AccordionKontent key={i} {...item} />
                        </div>
                    </section>
                );
            })}

        </SgLayout>
    );
};

export default SgAccordion;
